<template>
  <div class="bgcard">
    <div class="serverlist">
      <button @click="newly(3)">+ {{ $t("lang.Addcontract") }}</button>
	  <div class="searchlist">
		  <div class="select_b" style="margin-right: 0.2rem">
		    <span style="color: #bbbbbb">{{ $t("lang.Devicestatus") }}：</span>
		    <el-select
		      style="height: 0.4rem; width: 1.5rem"
		      v-model="parsm.stat"
		      :placeholder="$t('lang.Please')"
		      popper-class="select_a"
		      @change="stauts"
		    >
		      <el-option :label="$t('lang.whole')" value=""></el-option>
		      <el-option :label="$t('lang.Underimplementation')" :value="1"></el-option>
		      <el-option :label="$t('lang.Completed')" :value="0"></el-option>
		    </el-select>
		  </div>
		  <div class="query">
		    <div class="inputname">
		      <input
		        v-model="input"
		        :placeholder="$t('lang.Pleaseinputkeywords')"
		      />
		    </div>
		    <div class="searchbtn">
		      <button @click="sear">
		        <p>{{ $t("lang.search") }}</p>
		      </button>
		    </div>
		  </div>
	  </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="customerName"
          :label="$t('lang.Buyersname')"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.Targetaccountnumber')" align="center">
          <template #default="scope">
            <span v-if="scope.row.email">{{ scope.row.email }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.servicetime')"
          width="280"
          align="center"
        >
          <template #default="scope">
            <span
              >{{ scope.row.startTime | time }}{{ $t("lang.to")
              }}{{
                scope.row.endTime | time
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="flow"
          :label="$t('lang.Trafficquota')"
          align="center"
          width="270"
        >
        </el-table-column>
        <el-table-column
          prop="storage"
          :label="$t('lang.storagequota')"
          align="center"
		  width="230"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.state')" align="center">
          <template #default="scope">
            <span v-if="scope.row.state == 0">{{ $t("lang.Completed") }}</span>
            <span v-else>{{ $t("lang.Underimplementation") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.operation')" align="center" width="250">
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left"
                v-if="scope.row.state == 1"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="closemsg(scope.row, 2)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise" v-if="scope.row.state == 1">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.details")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_detail"
                   @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="detailsmsg(scope.row, 1)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              size="small"
              class="buttons"
              v-if="scope.row.state == 1"
              style="background-color: #434343"
              @click="closemsg(scope.row, 2)"
              >{{ $t("lang.edit") }}</el-button
            >
            <el-button
              size="small"
              class="buttons"
              style="background-color: #24b4de"
              @click="detailsmsg(scope.row, 1)"
              >{{ $t("lang.details") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :title="title"
      :visible.sync="showSee"
      :show-close="false"
      width="9.35rem"
      custom-class="seteqcs_a"
    >
      <div class="setmsgs" v-if="sign == 1">
          <div class="basic_input">
          <label>{{ $t("lang.Contractname") }}：</label>
          <div>{{ alone.name }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Nameoflessee") }}：</label>
          <div>{{ alone.customerName }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Targetaccountnumber") }}：</label>
          <div v-if="alone.email">{{ alone.email }}</div>
          <div v-else>/</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Leasetime") }}：</label>
          <div>{{ alone.startTime | time }} 至 {{ alone.endTime | time }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Trafficquota") }}：</label>
          <div>{{ alone.flow }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.storagequota") }}：</label>
          <div>{{ alone.storage }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.state") }}：</label>
          <div v-if="alone.state == 0">{{ $t("lang.Completed") }}</div>
          <div v-else>{{ $t("lang.Underimplementation") }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.contacts") }}：</label>
          <div>{{ alone.contact }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Contactnumber") }}：</label>
          <div>{{ alone.mobile }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.fixedtelephone") }}：</label>
          <div>{{ alone.phone }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.address") }}：</label>
          <div>{{ alone.addr }}</div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="1.6rem"
        v-else
      >
        <div style="font-size: 0.16rem; font-weight: bold">
          {{ $t("lang.Purchaseinformation") }}
        </div>
        <div class="setmsgs_a">
          <el-form-item :label="$t('lang.Contractname') + '：'" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Buyertype') + '：'" prop="type">
            <el-select v-model="ruleForm.type" :placeholder="$t('lang.Please')">
              <el-option
                :label="$t('lang.naturalperson')"
                :value="1"
              ></el-option>
              <el-option :label="$t('lang.EnterpriseV')" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('lang.Nameoflessee') + '：'"
            prop="customerName"
          >
            <el-input v-model="ruleForm.customerName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Targetaccountnumber') + '：'">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Leasetime') + '：'" prop="time">
            <el-date-picker
              v-model="ruleForm.time"
              class="SelectTime"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.Startdate')"
              :end-placeholder="$t('lang.Enddate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('lang.storagequota') + '：'" prop="storage">
            <el-input
              style="width: 2.1rem; margin-right: 0.1rem"
              v-model="ruleForm.storage"
              :placeholder="$t('lang.mages')"
            ></el-input
            ><span>MB</span>
          </el-form-item>
          <el-form-item :label="$t('lang.Trafficquota') + '：'" prop="flow">
            <el-input
              style="width: 2.1rem; margin-right: 0.1rem"
              v-model="ruleForm.flow"
              :placeholder="$t('lang.mages')"
            ></el-input
            ><span>MB</span>
          </el-form-item>
        </div>
        <div style="font-size: 0.16rem; font-weight: bold; margin-top: 0.3rem">
          {{ $t("lang.Customerinformation") }}
        </div>
        <div class="setmsgs_a">
          <el-form-item :label="$t('lang.contacts') + '：'" prop="contact">
            <el-input v-model="ruleForm.contact"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Contactnumber') + '：'" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.fixedtelephone') + '：'" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.address') + '：'" prop="addr">
            <el-input v-model="ruleForm.addr"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div>
        <span class="dialog-footer" v-if="sign == 1">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.return") }}</p>
          </button>
        </span>
        <span class="dialog-footer" v-else>
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button
            class="button_a"
            @click="submitForm('ruleForm')"
            v-if="sign == 2"
          >
            <p>{{ $t("lang.preservation") }}</p>
          </button>
          <button class="button_a" @click="submitForm('ruleForm')" v-else>
            <p>{{ $t("lang.submit") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as contract from "@/api/contract.js";
import * as RegExp from "@/ui-utils/RegExp.js";
export default {
  data() {
    var mobiles = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseentercontactphone"));
      } /* else if (!RegExp.mobile.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterthecorrectcontactphone")));
      } */ else {
        callback();
      }
    };
    var name = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseenterthecontractname"));
      } else {
        callback();
      }
    };
    var storage = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseenterstoragequota"));
      } else if (!RegExp.numbers.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterapositiveinteger")));
      } else if (value < -1) {
        callback(new Error(this.$t("lang.Pleaseenterapositiveinteger")));
      }
      {
        callback();
      }
    };
    var traffic = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseinputtrafficquota"));
      } else if (!RegExp.numbers.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterapositiveinteger")));
      } else if (value < -1) {
        callback(new Error(this.$t("lang.Pleaseenterapositiveinteger")));
      } else {
        callback();
      }
    };
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenFivetext: this.$t("lang.deletes"),
      seenOne: true,
      seenTwo: true,
      seenThree: true,
      seenFree: true,
      seenFive: true,

      input: "",
      tableData: [],
      parsm: {
        //获取合同列表参数
        page: 1,
        size: 10,
        type: 2,
        str: "",
		stat:''
      },
      showSee: false,
      title: "",
      sign: "", //判断打开哪个模态框（详情、编辑、添加）
      alone: "", //详情数据
      ruleForm: {
        //添加合同参数
        name: "",
        type: "",
        customerName: "",
        email: "",
        time: "",
        storage: "",
        flow: "",
        contact: "",
        mobile: "",
        phone: "",
        addr: "",
      },
      rules: {
        //添加合同正则验证
        name: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthecontractname"),
            validator: name,
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectthetypeofrentdebit"),
            trigger: "blur",
          },
        ],
        customerName: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterleaseholder"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthetarget"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("lang.Pleaseenterthecorrectemail"),
            trigger: ["blur", "change"],
          },
        ],
        time: [
          {
            required: true,
            message: this.$t("lang.Pleaseselecttheleasetime"),
            trigger: "change",
          },
        ],
        storage: [
          {
            required: true,
            validator: storage,
            trigger: ["blur"],
          },
        ],
        flow: [
          {
            required: true,
            validator: traffic,
            trigger: ["blur"],
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t("lang.Pleaseentercontact"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: mobiles,
            trigger: "blur",
          },
        ],
        billInfoID: "", //合约id
        customerID: "", //用户id
      },
    };
  },
  filters: {
    //过滤时间
    time(val) {
      let sk = val.split(" ");
      return sk[0];
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag1 = 1
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData.list[i].id && flag === 0){
            this.tableData.list[i].flag2 = 1
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
       for (let i in this.tableData.list) {
        if (index === 1 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag1 = 0
          }
        }
         if (index === 2 ) {
          if(ids == this.tableData.list[i].id && flag === 1){
            this.tableData.list[i].flag2 = 0
          }
        }
      }
    },
	stauts(){
		this.parsm.page = 1;
		this.parsm.size = 10;
		this.getList();
	},
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取合约列表
    async getList() {
      let res = await contract.list(this.parsm);
      console.log(res);
      if (res.data) {
         res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
        });
        this.tableData = res.data;
      }
    },
    //搜索筛选合约列表
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.str = this.input;
      this.getList();
    },
    //添加合约
    newly(ind) {
      this.sign = ind;
      this.title = this.$t("lang.Addservicecontract");
      this.showSee = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
        for (let key in this.ruleForm) {
          this.ruleForm[key] = "";
        }
      });
    },
    //编辑合约
    closemsg(row, ind) {
      this.billInfoID = row.billInfoID;
      this.customerID = row.customerID;

      this.ruleForm.customerName = row.customerName;
      this.ruleForm.type = row.type;
      this.ruleForm.name = row.name;
      this.ruleForm.email = row.email;
      this.ruleForm.time = [row.startTime, row.endTime];
      this.ruleForm.contact = row.contact;
      this.ruleForm.mobile = row.mobile;
      this.ruleForm.phone = row.phone;
      this.ruleForm.addr = row.addr;
      this.ruleForm.flow = row.flow;
      this.ruleForm.storage = row.storage;

      this.sign = ind;
      this.title = this.$t("lang.Editcontract");
      this.showSee = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
    },
    //合约详情
    detailsmsg(row, ind) {
      this.alone = row;
      this.sign = ind;
      this.title = this.$t("lang.Contractdetails");
      this.showSee = true;
    },
    //提交保存表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          if (this.sign == 3) {
            let parsm = {
              addr: this.ruleForm.addr,
              contact: this.ruleForm.contact,
              customerName: this.ruleForm.customerName,
              billInfoType: 2,
              email: this.ruleForm.email,
              endTime: this.ruleForm.time[1],
              mobile: this.ruleForm.mobile,
              name: this.ruleForm.name,
              phone: this.ruleForm.phone,
              startTime: this.ruleForm.time[0],
              type: this.ruleForm.type,
              storage: this.ruleForm.storage,
              flow: this.ruleForm.flow,
            };
            contract.add(parsm).then((res) => {
              console.log(res);
              this.$message.success(this.$t("lang.Submitted"));
              this.showSee = false;
              this.getList();
            });
          } else if (this.sign == 2) {
            let parsm = {
              addr: this.ruleForm.addr,
              contact: this.ruleForm.contact,
              customerName: this.ruleForm.customerName,
              billInfoType: 2,
              email: this.ruleForm.email,
              endTime: this.ruleForm.time[1],
              mobile: this.ruleForm.mobile,
              name: this.ruleForm.name,
              phone: this.ruleForm.phone,
              startTime: this.ruleForm.time[0],
              type: this.ruleForm.type,
              billInfoId: this.billInfoID,
              customerId: this.customerID,
              storage: this.ruleForm.storage,
              flow: this.ruleForm.flow,
            };
            contract.edit(parsm).then((res) => {
              console.log(res);
              this.$message.success(this.$t("lang.Submitted"));
              this.showSee = false;
              this.getList();
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-date-editor .el-range-separator {
  width: 10% !important;
  white-space: nowrap;
}
.el-form-item__label {
  white-space: nowrap !important;
}
</style>
<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}

.serverlist {
  display: flex;
  justify-content: space-between;

  > button {
    // width: 112px;
    // height: 50px;
    padding: 0.1rem 0.15rem;
    border: none;
    outline-style: none;
    background: #307b37;
    border-radius: 0.1rem;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}

.operation {
  display: flex;
}

.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
/* 合约详情模态框 */
.setmsgs {
  .basic_input:first-child {
    margin-top: 0;
  }

  .basic_input {
    margin-top: 0.2rem;
    display: flex;

    label {
      min-width: 1rem;
      max-width: 2.15rem;
      white-space: nowrap;
      font-size: 0.16rem;
      font-weight: bold;
      color: #666666;
    }

    div {
      font-size: 0.16rem;
      color: #333333;
    }

    .serial {
      div:first-child {
        margin-top: 0;
      }

      div {
        margin-top: 0.1rem;
      }
    }
  }
}

/* 添加、编辑模态框 */
.setmsgs_a {
  margin-top: 0.2rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .el-input,
  .el-select,
  .SelectTime {
    width: 2.4rem;
  }
}

.button_a {
  border: none;
  outline-style: none;
  margin-top: 0.36rem;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
.bottom_tabes{
	display: flex;
	flex-flow: row;
	justify-content: flex-end !important;
	margin-right: 30%;
	align-items: center;
}
</style>
